import * as auth from "../actions/auth.js";
import jwtDecode from "jwt-decode";
// import Cookies from 'universal-cookie';
// const cookies = new Cookies();

const initialState = {
  isAuth: false,
  loading: false,
  otp: null,
  mfaEnabled: false,
  token: null,
  error: null,
  userType: null,
  appToken: null,
  email: null,
  inHomeApp: true,
  practices: [],
  firstName: null,
  lastName: null,
  fullName: null,
  phoneNumber: null,
  currentPractice: null,
  currentPracticeID: null,
  eCommerce: false,
  currentUserId: null,
  currentPG2UserId: null,
  goToPatientDasboard: false,
  enableECommerce: false,
  termsAgreed: false,
  rTermsAgreed: false,
  postLogin: false,
  showDobBanner: false,
  errorHTML: "",
  masterAdmin: false,
  region: [],
  country: null,
  isStaff: null,
  userPermissions: null,
  practitioner_id: null,
  gender: null,
  pure_account_details: {},
  dob: null,
  skin_color: null,
  userInfoTk: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case auth.AUTH_REQUEST: {
      return { ...state, loading: true, isAuth: false };
    }
    case auth.AUTH_SUCCESS: {
      console.log("action.payload ----------->", action.payload);
      if (action.payload.password_redirect_url) {
        return {
          ...state,
        };
      }
      const { practices, user_type, user } = jwtDecode(action.payload.token);
      console.log(practices, "-=-=-=-practicess");
      return {
        ...state,
        error: false,
        isAuth: true,
        currentUserId: user.id,
        otp: action.payload.otp,
        mfaEnabled: false,
        email: user.email,
        postLogin: action.payload.post_login,
        firstName: user.first_name,
        lastName: user.last_name,
        fullName: user.full_name,
        phoneNumber: user.phone_number,
        loading: false,
        inHomeApp: true,
        token: action.payload.token,
        userType: user_type,
        enableECommerce: user.enable_ecommerce,
        currentPG2UserId: user.pg2_practitioner_id
          ? user.pg2_practitioner_id
          : user.pg2_patient_id,
        practices: Array.isArray(practices) ? practices : [practices],
        goToPatientDasboard:
          user_type === "Patient" ? user.welcome_shown : false,
        termsAgreed: Array.isArray(practices)
          ? practices[0]?.terms_agreed
          : practices?.terms_agreed,
        rTermsAgreed: Array.isArray(practices)
          ? practices[0]?.rialto_terms_agreed
          : practices?.rialto_terms_agreed,
        language: user.language,
        masterAdmin: user.master_admin,
        region: user.region || [],
        country: user.country,
        isStaff: user.is_staff,
        userPermissions: user.user_permissions,
        practitioner_id: user.practitioner_id,
        userInfoTk: user.user_info_tk,
      };
    }
    case auth.FORGOT_EMAIL_SENT_SUCCESS: {
      return {
        ...state,
        inHomeApp: true,
        loading: false,
      };
    }
    case auth.AUTH_FAILURE: {
      console.log("in authfail", action.payload);
      return {
        ...state,
        error: action.error,
        errorHTML: "",
        inHomeApp: true,
        loading: false,
      };
    }
    case auth.LOGOUT_ST: {
      // localStorage.removeItem("persist:" + process.env.REACT_APP_STORE_KEY);
      return {
        ...state,
        isAuth: false,
        loading: false,
        mfaEnabled: false,
        token: null,
        userType: null,
        appToken: null,
        email: null,
        inHomeApp: true,
        practices: [],
        firstName: null,
        lastName: null,
        fullName: null,
        phoneNumber: null,
        // currentPractice: null,
        // currentPracticeID: null,
        currentPG2UserId: null,
        goToPatientDasboard: false,
        eCommerce: false,
        enableECommerce: false,
        currentUserId: null,
        postLogin: false,
        masterAdmin: false,
        country: null,
        termsAgreed: false,
        rTermsAgreed: false,
        showDobBanner: false,
        userInfoTk: false,
      };
    }
    case auth.REFRESH_TOKEN_REQUEST: {
      return {
        ...state,
      };
    }
    case auth.REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        token: action.payload.token,
      };
    }
    case auth.REFRESH_TOKEN_FAILURE: {
      return {
        ...state,
      };
    }
    case auth.CHANGE_IS_AUTH: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case auth.AGREED_TERMS_REQUEST: {
      return {
        ...state,
      };
    }
    case auth.TOGGLE_DOB_BANNER_STATE: {
      return {
        ...state,
        showDobBanner: !state.showDobBanner,
      };
    }
    case auth.AGREED_TERMS_SUCCESS: {
      return {
        ...state,
        termsAgreed: true,
      };
    }
    case "RELOAD_STATE": {
      const {
        token,
        user,
        practiceID,
        practices,
        practice,
        user_type,
        app_token,
        eCommerce,
        showDobBanner,
      } = action.payload;
      return {
        ...state,
        postLogin: false,
        mfaEnabled: true,
        isAuth: true,
        error: false,
        token: token,
        appToken: app_token,
        userType: user_type,
        inHomeApp: false,
        currentUserId: user?.id,
        email: user?.email,
        gender: user?.gender,
        dob: user?.dob,
        skin_color: user?.skin_color,
        firstName: user?.first_name,
        lastName: user?.last_name,
        fullName: user?.full_name,
        phoneNumber: user?.phoneNumber,
        practices: Array.isArray(practices) ? practices : [practices],
        currentPractice: practice,
        currentPracticeID: practiceID,
        eCommerce: user?.enable_ecommerce,
        enableECommerce: user?.enable_ecommerce,
        currentPG2UserId: user?.pg2_practitioner_id
          ? user?.pg2_practitioner_id
          : user?.pg2_patient_id,
        goToPatientDasboard:
          user_type === "Patient" ? user?.welcome_shown : false,
        termsAgreed: action.payload?.termsAgreed === "true",
        rTermsAgreed: Array.isArray(practices)
          ? practices[0]?.rialto_terms_agreed
          : practices?.rialto_terms_agreed,
        language: user?.language,
        masterAdmin: user?.master_admin,
        region: user?.region || [],
        country: user?.country,
        isStaff: user?.is_staff,
        userPermissions: user?.user_permissions,
        practitioner_id: user?.practitioner_id,
        userInfoTk: user?.user_info_tk,
        showDobBanner
      };
    }
    case "CLOSE_ERROR":
      return { ...state, error: {} };

    case auth.REG_PAN_REQUEST: {
      return {
        ...state,
      };
    }
    case auth.REG_PAN_SUCCESS: {
      return {
        ...state,
        pure_account_details: action.payload,
      };
    }
    case auth.REG_PAN_FAILURE: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default authReducer;

import React, { useState, useEffect, useMemo } from "react";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import { connect, useSelector } from "react-redux";
import TableFooter from "./TableFooter";
import TextInputWithIcon from "../../../components/TextInputWithIcon";
import PgCheckbox from "../../../components/PgCheckbox";
import { Accordion, useAccordionToggle } from "react-bootstrap";
import FilterIcon from "../../../assets/filterIcon.png";
import FilterComponent from "../../../components/Admin/StaffFilterComponent";
import AddPractitioner from "./AddStaff";
import StaffBatchFn from "../../../components/Admin/Staff/StaffBatchFn";
import { useNavigate } from "react-router-dom";
import L_PatientList from "../../../components/LoadingEffectComponent/L_PatientList";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

export const PractitionerTable = ({
  practitioners,
  error,
  totalData,
  updateStaffTable,
  loading,
  selectedRegion,
}) => {
  const navigate = useNavigate();
  const regions = useSelector((state) => state.auth.region);

  const [selectedPractitionerArray, setSelectedPractitionerArray] = useState(
    []
  );

  const [controlledPageCount, setControlledPageCount] = useState(1);
  const [sPageIndex, setSPageIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [userStatus, setUserStatus] = useState([]);
  const [pracTypeFilter, setpracTypeFilter] = useState([]);
  const [showAddPractitionerModal, setShowAddPractitionerModal] =
    useState(false);
  const [recentURL, setrecentURL] = useState("");

  const onSelectAll = () => {
    setSelectedPractitionerArray((spa) => {
      if (spa.length === practitioners.length) return [];
      else return practitioners.map((xx) => xx.id);
    });
  };

  const onSelectPractitioner = (row) => {
    setSelectedPractitionerArray((spa) => {
      if (spa.includes(row.id)) return spa.filter((_id) => _id !== row.id);
      else return [...spa, row.id];
    });
  };

  const [sortName, setsortName] = useState("");
  const [sortEmail, setsortEmail] = useState("");
  const [sortStatus, setsortStatus] = useState("");
  const [sortLicense, setsortLicense] = useState("");
  const [sortMember, setsortMember] = useState("");
  const [sortLM, setsortLM] = useState("");
  const { t, i18n } = useTranslation();

  const resetSort = (field) => {
    setsortLM(field === "last" ? sortLM : "");
    setsortMember(field === "member" ? sortMember : "");
    setsortLicense(field === "license" ? sortLicense : "");
    setsortStatus(field === "status" ? sortStatus : "");
    setsortEmail(field === "email" ? sortEmail : "");
    setsortName(field === "name" ? sortName : "");
  };

  const manualSort = (e) => {
    const field = e.target.innerText.split(" ")[0]?.toLowerCase();
    resetSort(field);
    if (field === "email") {
      setsortEmail(
        sortEmail === "" ? "DESC" : sortEmail === "DESC" ? "ASC" : "DESC"
      );
      updateStaffTable(
        `${recentURL}&sort_key=email&sort_value=${
          sortEmail === "" ? "DESC" : sortEmail === "DESC" ? "ASC" : "DESC"
        }`
      );
    }
    if (field === "name") {
      setsortName(
        sortName === "" ? "DESC" : sortName === "DESC" ? "ASC" : "DESC"
      );
      updateStaffTable(
        `${recentURL}&sort_key=last_name&sort_value=${
          sortName === "" ? "DESC" : sortName === "DESC" ? "ASC" : "DESC"
        }`
      );
    }
    if (field === "status") {
      setsortStatus(
        sortStatus === "" ? "DESC" : sortStatus === "DESC" ? "ASC" : "DESC"
      );
      updateStaffTable(
        `${recentURL}&sort_key=aasm_state&sort_value=${
          sortStatus === "" ? "DESC" : sortStatus === "DESC" ? "ASC" : "DESC"
        }`
      );
    }
    if (field === "license") {
      setsortLicense(
        sortLicense === "" ? "DESC" : sortLicense === "DESC" ? "ASC" : "DESC"
      );
      updateStaffTable(
        `${recentURL}&sort_key=license&sort_value=${
          sortLicense === "" ? "DESC" : sortLicense === "DESC" ? "ASC" : "DESC"
        }`
      );
    }
    if (field === "member") {
      setsortMember(
        sortMember === "" ? "DESC" : sortMember === "DESC" ? "ASC" : "DESC"
      );
      updateStaffTable(
        `${recentURL}&sort_key=created_at&sort_value=${
          sortMember === "" ? "DESC" : sortMember === "DESC" ? "ASC" : "DESC"
        }`
      );
    }
    if (field === "last") {
      setsortLM(sortLM === "" ? "DESC" : sortLM === "DESC" ? "ASC" : "DESC");
      updateStaffTable(
        `${recentURL}&sort_key=updated_at&sort_value=${
          sortLM === "" ? "DESC" : sortLM === "DESC" ? "ASC" : "DESC"
        }`
      );
    }
  };

  const continueSort = (url) => {
    if (sortEmail) {
      updateStaffTable(`${url}&sort_key=email&sort_value=${sortEmail}`);
    } else if (sortName) {
      updateStaffTable(`${url}&sort_key=last_name&sort_value=${sortName}`);
    } else if (sortStatus) {
      updateStaffTable(`${url}&sort_key=aasm_state&sort_value=${sortStatus}`);
    } else if (sortLicense) {
      updateStaffTable(`${url}&sort_key=license&sort_value=${sortLicense}`);
    } else if (sortMember) {
      updateStaffTable(`${url}&sort_key=created_at&sort_value=${sortMember}`);
    } else if (sortLM) {
      updateStaffTable(`${url}&sort_key=updated_at&sort_value=${sortLM}`);
    } else updateStaffTable(url);
  };

  const column = [
    {
      Header: (
        <div className="position-relative practitioner-batch-checkbox">
          <PgCheckbox
            checked={
              selectedPractitionerArray.length > 0 &&
              selectedPractitionerArray.length === practitioners.length
            }
            onCheck={onSelectAll}
          />
        </div>
      ),
      accessor: "id",
      sortable: false,
      filterable: false,
      Cell: (value) => {
        const { row } = value;
        return (
          //   <a className="pg-text" onClick={() => {}}>
          <div className="position-relative practitioner-batch-checkbox">
            <PgCheckbox
              checked={selectedPractitionerArray.includes(row.values.id)}
              onCheck={(e) => onSelectPractitioner(row.values)}
            />
          </div>
          //   </a>
        );
      },
    },
    {
      Header: `${t("name")} ${sortName === "DESC" ? " ↓" : " ↑"}`,
      accessor: "full_name",
    },
    {
      Header: `${t("email")} ${sortEmail === "DESC" ? " ↓" : " ↑"}`,
      accessor: "email",
    },
    {
      Header: `${t("status")} ${sortStatus === "DESC" ? " ↓" : " ↑"}`,
      accessor: "status",
      Cell: ({ value }) => {
        return t(_.lowerCase(value));
      },
    },
    {
      Header: `${t("member_since")} ${sortMember === "DESC" ? " ↓" : " ↑"}`,
      accessor: "member_since",
      Cell: ({ value }) => {
        return format(new Date(value), "MMM do, yyyy", {
          locale: i18n.language === "fr" ? fr : undefined,
        });
      },
    },
    {
      Header: `${t("last_modified")} ${sortLM === "DESC" ? " ↓" : " ↑"}`,
      accessor: "updated_at",
      Cell: ({ value }) => {
        return format(new Date(value), "MMM do, yyyy", {
          locale: i18n.language === "fr" ? fr : undefined,
        });
        // return format(value);
      },
    },
  ];

  const columns = useMemo(
    () => column,
    [selectedPractitionerArray, practitioners]
  );
  const data = useMemo(() => practitioners, [practitioners]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: sPageIndex,
        pageSize: 100,
        // pageSize: 3
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetSortBy: false,
      // autoResetPage: true,
      autoResetPage: false,
      disableSortRemove: true,
      defaultCanSort: true,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    setControlledPageCount(Math.ceil(totalData / pageSize));
  });

  useEffect(() => {
    gotoPage(0);
  }, [searchText]);

  useEffect(() => {
    let url = `per_page=${pageSize}&page=${pageIndex + 1}`;
    if (searchText.trim().length > 1) url += `&search=${searchText}`;
    if (userStatus.length > 0) url += `&aasm_state=${userStatus}`;
    if (pracTypeFilter.length > 0) url += `&account_type=${pracTypeFilter}`;
    setrecentURL(url);
    setSelectedPractitionerArray([]);
    const delayDebounceFn = setTimeout(() => {
      continueSort(url);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [pageSize, pageIndex, searchText, userStatus, pracTypeFilter]);

  const __updatePractitionerTable = () => {
    updateStaffTable(recentURL);
  };

  const clearFilter = () => {
    setUserStatus([]);
    setpracTypeFilter([]);
  };

  return (
    <div className="practitioner-table">
      <div className="mt-5 d-flex  justify-content-between">
        <div
          className="d-flex justify-content-between"
          style={{ width: "20%" }}
        >
          <Accordion>
            <div className="w-100 d-flex justify-content-between px-1 px-xl-0">
              <div
                className="d-flex justify-content-between  mt-2"
                style={{
                  borderBottom: showFilterOption && "0.5px solid #808080",
                }}
              >
                <CustomToggle
                  showFilterOption={showFilterOption}
                  setShowFilterOption={setShowFilterOption}
                  eventKey="0"
                />
                {(userStatus.length > 0 || pracTypeFilter.length > 0) &&
                  showFilterOption && (
                    <div>
                      <span
                        className="pg-link mt-2"
                        onClick={clearFilter}
                        id="reset-filter__patientlist"
                      >
                        <i
                          className="fa fa-times-circle textc-primary me-2"
                          aria-hidden="true"
                        ></i>
                        {t("reset")}
                      </span>
                    </div>
                  )}
              </div>
            </div>
            <div>
              <Accordion.Collapse eventKey="0" as="div">
                <FilterComponent
                  pracTypeFilter={pracTypeFilter}
                  setpracTypeFilter={setpracTypeFilter}
                  userStatus={userStatus}
                  setUserStatus={setUserStatus}
                />
              </Accordion.Collapse>
            </div>
          </Accordion>
        </div>
        <TextInputWithIcon
          label="Practitioner Name"
          placeholder={t("staff_members_search_ph")}
          value={searchText}
          inputHandler={(e) => setSearchText(e.target.value)}
          Icon={
            <i className="fa fa-search text-secondary" aria-hidden="true"></i>
          }
        />
        <div className="justify-content-end">
          <button
            id="add-patient-btn__patientlist"
            className="btn btn-outline px-4 ms-2"
            onClick={() => setShowAddPractitionerModal(true)}
          >
            <i className="fas fa-plus mx-1"></i>{" "}
            {t("staff_members_table_btn_1")}
          </button>
        </div>
      </div>

      <div className="my-3">
        {loading ? (
          <L_PatientList />
        ) : (
          <>
            <StaffBatchFn
              filter={pracTypeFilter}
              setSelectedPractitioner={setSelectedPractitionerArray}
              practitioners={practitioners}
              updatePractitionerTable={__updatePractitionerTable}
              selectedPractitioner={selectedPractitionerArray}
            />

            <table
              {...getTableProps()}
              className="pg-table practitioners-table my-2"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        onClick={manualSort}
                      >
                        {column.render("Header")}
                        <span className="fs-4">
                          {column.isSorted
                            ? column.isSortedDesc
                              ? "↓"
                              : "↑"
                            : ""}{" "}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {error && (
                <tbody>
                  <tr>
                    <td colSpan="8">
                      <div className="w-100 text-center">
                        {t("staff_members_table_content_1")}
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
              {practitioners.length < 1 && !error && (
                <tbody>
                  <tr>
                    <td colSpan="8">
                      <div className="w-100 text-center">
                        {t("staff_members_table_no_record")}
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
              {practitioners.length > 0 && !error && (
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
            <TableFooter
              pageSize={pageSize}
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              nextPage={nextPage}
              previousPage={previousPage}
              setPageSize={setPageSize}
              pageIndex={pageIndex}
              pageOptions={pageOptions}
            />
          </>
        )}
      </div>
      {showAddPractitionerModal && (
        <AddPractitioner
          showModal={true}
          onCancel={() => setShowAddPractitionerModal(false)}
          updateStaffTable={__updatePractitionerTable}
          context="practitioner-list"
          selectedRegion={selectedRegion}
        />
      )}
    </div>
  );
};

export default PractitionerTable;

function CustomToggle({
  children,
  eventKey,
  setShowFilterOption,
  showFilterOption,
}) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {});
  const { t, i18n } = useTranslation();
  return (
    <span
      className="font-weight-bold cursor-pointer my-2"
      onClick={(e) => {
        decoratedOnClick(e);
        setShowFilterOption(!showFilterOption);
      }}
    >
      <span className="pg-link">
        <img src={FilterIcon} alt="" className="me-2 mt-1" />
        {t("filter")}
      </span>
    </span>
  );
}

import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Footer from "../components/Footer";
// pages
import HomePage from "../Pages/HomePage";
import LoginPage from "../Pages/Auth/LoginPage";
import DomainPage from "../Pages/Auth/Domain";
import PG2PasswordUpdatePage from "../Pages/Auth/PG2PasswordUpdatePage";
import Pg2UserLandingPage from "../Pages/Auth/Pg2UserLandingPage";
// admin pages
import AdminDashboard from "../Pages/Admin/Dashboard";
import AdminPractitionerList from "../Pages/Admin/Practitioner/PractitionerList";
import AdminPatientList from "../Pages/Admin/Patient/PatientList";
import AdminUserList from "../Pages/Admin/AdminUser/AdminUserList";
import ProductionTask from "../Pages/Admin/ProductionTask";
import AdminStaffList from "../Pages/Admin/Staff/StaffList";

// practice pages
import PractitionerDashboard from "../Pages/Practice/Dashboard";
import PractitionerAccount from "../Pages/Practice/Account";
import StaffAccount from "../Pages/Practice/StaffAccount";
import ForgotPasswordPage from "../Pages/Auth/ForgotPasswordPage";
import AddPatientPage from "../Pages/Practice/AddPatientPage";
import AddPatientBulkPage from "../Pages/Practice/AddPatientBulkPage";
import PatientList from "../Pages/Practice/PatientList";
import StaffList from "../Pages/Practice/Staff/StaffList";
import RialtoPage from "../components/RialtoPage";
import RialtoManagement from "../Pages/RialtoManagement";
import ManagePracticeData from "../Pages/Practice/ManagePracticeData";
import StripeRedirect from "../Pages/StripeRedirect";
import ViewPatientAnswers from "../Pages/Practice/ViewPatientAnswers";
// patient Pages
import GeneticUpload from "../Pages/Patient/GeneticUpload";
import PatientDashboard from "../Pages/Patient/PatientDashboard";
import PatientAccount from "../Pages/Patient/Account";
import PatientReportDashboard from "../Pages/Patient/Report/Dashboard";
import PatientRegistrationPage from "../Pages/Auth/PatientRegistrationPage";
import PatientPastReports from "../Pages/Patient/Report/PastReport";
import Questionnaire from "../Pages/Patient/Questionnaire";
// Routes and Layoutes
import {
  AdminProtectedRoute,
  PracticeProtectedRoute,
  LoginRoute,
  PatientRoute,
  MFARoute,
} from "../routes/route_constants";

import AdminLayout from "./AdminLayout";
import PracticeLayout from "./PracticeLayout";
import LoginLayout from "./LoginLayout";
import LabUpload from "../Pages/Practice/LabUpload";
import PracticeGeneticUpload from "../Pages/Practice/GeneticUpload";
import ReportSummary from "../Pages/Report/ReportSummary";
import ReportDiagrams from "../Pages/Report/ReportDiagrams";
import GeneAnalysisSummary from "../Pages/Report/GeneAnalysisSummary";
import SupplementalGenotype from "../Pages/Report/SupplementalGenotype";
import ResetPasswordPage from "../Pages/Auth/ResetPasswordPage";
import Catalog from "../Pages/Practice/Catalog";
import TraitPage from "../Pages/Report/TraitsPage";
import ReviewLabResult from "../components/Practice/Lab/ReviewLabResult";
import PastReport from "../Pages/Report/PastReport";
import ReviewQuestion from "../Pages/Patient/ReviewQuestion";
import ThankYouQuestionnaire from "../Pages/Patient/ThankYouQuestionnaire";
import { Loading } from "../components/Loading";
import OtpVerificationPage from "../Pages/Auth/OtpVerificationPage";
import ManualEntry from "../components/Practice/Lab/ManualEntry";
import PatientManualEntry from "../components/Patient/Lab/ManualEntry";
import { useTranslation } from "react-i18next";
import HomePageLayout from "../Layout/HomePageLayout";
// static pages
import _404 from "../Pages/_404";
import DiscoverPG from "../components/StaticPages/Provider/DiscoverPG";
import KnowTheScience from "../components/StaticPages/Provider/KnowTheScience";
import Registration from "../components/StaticPages/Provider/Registration";
import LegalDesclaimer from "../components/StaticPages/LegalDesclaimer";
import Privacy from "../components/StaticPages/Privacy";
import TermsAndUse from "../components/StaticPages/TermsAndUse";
import DispensaryTermsAndUse from "../components/StaticPages/DispensaryTermsAndUse";
import ContactUs from "../components/StaticPages/ContactUs";
import EnhanceYourPractice from "../components/StaticPages/Provider/EnhanceYourPractice";
import EducationalScience from "../components/StaticPages/Provider/EducationalScience";
import AboutPE from "../components/StaticPages/Provider/AboutPE";
import HowToGetStarted from "../components/StaticPages/Provider/HowToGetStarted";
import ProviderFAQ from "../components/StaticPages/Provider/ProviderFAQ";
import WhyPG from "../components/StaticPages/Patient/WhyPG";
import WhatYouGet from "../components/StaticPages/Patient/WhatYouGet";
import PatientGetStarted from "../components/StaticPages/Patient/PatientGetStarted";
import AboutPEPatient from "../components/StaticPages/Patient/AboutPEPatient";
import PatientFAQ from "../components/StaticPages/Patient/PatientFAQ";
import GdprFAQ from "../components/StaticPages/Patient/GdprFAQ";
import RialtoPractitionerFAQ from "../components/StaticPages/RialtoPractitionerFAQ";
import RialtoPatientFAQ from "../components/StaticPages/RialtoPatientFAQ";
import CustomerService from "../components/StaticPages/CustomerService";
import MeetOutTeam from "../components/StaticPages/MeetOutTeam";
import PostLogin from "../components/StaticPages/PostLogin";
import ResearchAbstract from "../components/StaticPages/ResearchAbstract";
import UKProviderFAQ from "../components/StaticPages/Provider/UKProviderFAQ";
import CAProviderFAQ from "../components/StaticPages/Provider/CAProviderFAQ";
import CAPatientFAQ from "../components/StaticPages/Patient/CAPatientFAQ";
import UKPatientFAQ from "../components/StaticPages/Patient/UKPatientFAQ";
import UKMeetOutTeam from "../components/StaticPages/UKMeetOutTeam";
import CAMeetOutTeam from "../components/StaticPages/CAMeetOutTeam";
import TermsAndUseCA from "../components/StaticPages/TermsAndUseCA";
import TermsAndUseUK from "../components/StaticPages/TermsAndUseUK";
import PrivacyUK from "../components/StaticPages/PrivacyUK";
import PrivacyCA from "../components/StaticPages/PrivacyCA";
import UKCookiesNotice from "../components/StaticPages/UKCookiesNotice";
import ManageDataUK from "../Pages/Patient/ManageDataUK";
import PracticeManageDataUK from "../Pages/Practice/PracticeManageDataUK";
import PracticeGDPRPage from "../Pages/Practice/PracticeGDPRPage";
import PracticePatientGDPRLogs from "../Pages/Practice/PracticePatientGDPRLogs";
import AdminPracList from "../Pages/Admin/GDPR/AdminPracGDPRList";
import AdminPatientGDPRList from "../Pages/Admin/GDPR/AdminPatientGDPRList";
import AdminPracGDPRList from "../Pages/Admin/GDPR/AdminPracGDPRList";
import PractitionerReport from "../Pages/Admin/CSVReport/PractitionerReport";
import PatientReport from "../Pages/Admin/CSVReport/PatientReport";
import PrsScorePatient from "../Pages/Practice/PrsScorePatient";
import PasswordUpdatePage from "../Pages/Auth/PasswordUpdatePage";
import CACookiesNotice from "../components/StaticPages/CACookiesNotice";

// pg 4.0
import MSQ from "../Pages/PG4/MSQuestionnaires";
import PatientLabUpload from "../Pages/Patient/PatientLabUpload";
import PG4Dashboard from "../Pages/PG4/Dashboard";
import AccountSection from "../Pages/PG4/AccountSection";
import PatientLayout from "./PatientLayout";
import ReportSummaryPage from "../Pages/PG4/ReportSummaryPage";
import PatientReviewLabResult from "../components/Practice/Lab/PatientReviewLabResult";
import MSQStatus from "../Pages/PG4/MSQStatus";
import HealthDatNotice from "../components/StaticPages/HealthDataNotice";
import HomePageUK from "../Pages/HomePageUK";
import PatientSelfRegister from "../Pages/Auth/PatientSelfRegister";
import useLanguageConditions from "../hooks/useLanguageConditions";

function MainLayout({}) {
  // redux state variable
  const isAuth = useSelector((state) => state.auth.isAuth);
  const userType = useSelector((state) => state.auth.userType);
  const inHomeApp = useSelector((state) => state.auth.inHomeApp);
  const mfaEnabled = useSelector((state) => state.auth.mfaEnabled);
  const otp = useSelector((state) => state.auth.otp);
  const lang = useSelector((state) => state.auth.language);
  const { t, i18n } = useTranslation();
  const { isCA, isUK, isUS } = useLanguageConditions();

  return (
    <>
      <Routes>
        <Route path="/loading" element={<Loading />} />
        <Route>
          {/*Home page route*/}
          <Route element={<HomePageLayout />}>
            <Route path="/" element={isUK ? <HomePageUK /> : <HomePage />} />
            <Route
              path="/trait-category-abstracts"
              element={<ResearchAbstract />}
            />
            <Route path="/hcp/educational_1" element={<PostLogin />} />
            <Route path="/hcp/about-puregenomics" element={<DiscoverPG />} />
            <Route path="/hcp/about-pureinsight" element={<DiscoverPG />} />
            <Route path="/hcp/discover-pureinsight" element={<DiscoverPG />} />
            <Route
              path="/hcp/snps-genetic-testing"
              element={<KnowTheScience />}
            />
            <Route
              path="/hcp/practitioner-benefits"
              element={<EnhanceYourPractice />}
            />
            <Route path="/hcp/educational-page" element={<PostLogin />} />
            <Route path="/hcp/nutritional-support" element={<AboutPE />} />
            <Route path="/hcp/getting-started" element={<HowToGetStarted />} />
            <Route path="/hcp/registration" element={<Registration />} />
            <Route
              path="/hcp/faqs"
              element={isUK ? <UKProviderFAQ /> : <ProviderFAQ />}
            />
            <Route path="/hcp-faqs" element={<CAProviderFAQ />} />
            <Route path="/patient/why-puregenomics" element={<WhyPG />} />
            <Route path="/patient/why-pureinsight" element={<WhyPG />} />
            <Route path="/patient/what-you-get" element={<WhatYouGet />} />
            <Route
              path="/patient/how-to-get-started"
              element={<PatientGetStarted />}
            />
            <Route
              path="/patient/faqs"
              element={isUK ? <UKPatientFAQ /> : <PatientFAQ />}
            />
            <Route path="/patient-faqs" element={<CAPatientFAQ />} />
            <Route path="/patient/gdpr-faqs" element={<GdprFAQ />} />
            <Route
              path="/patient/nutritional-support"
              element={<AboutPEPatient />}
            />
            <Route
              path="/patient/customer-service"
              element={<CustomerService />}
            />
            <Route
              path="/patient/self-register"
              element={<PatientSelfRegister />}
            />
            <Route path="/legal-disclaimer" element={<LegalDesclaimer />} />
            <Route
              path="/health-data-notice"
              element={isUS ? <HealthDatNotice /> : <_404 />}
            />
            <Route path="/disclaimer" element={<LegalDesclaimer />} />
            <Route
              path="/privacy"
              element={isUK ? <PrivacyUK /> : <Privacy />}
            />
            <Route path="/privacy-policy" element={<PrivacyCA />} />
            <Route
              path="/terms-use"
              element={isUK ? <TermsAndUseUK /> : <TermsAndUse />}
            />
            <Route path="/terms-of-service" element={<TermsAndUseCA />} />
            <Route
              path="/dispensary-terms-use"
              element={<DispensaryTermsAndUse />}
            />
            <Route
              path="/practitioner-faq"
              element={<RialtoPractitionerFAQ />}
            />
            <Route path="/patient/patient-faq" element={<RialtoPatientFAQ />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route
              path="/our-team"
              element={
                isUK ? <UKMeetOutTeam /> : isCA ? <CAMeetOutTeam /> : <_404 />
              }
            />
            <Route
              path="/cookies-notice"
              element={isUK ? <UKCookiesNotice /> : <CACookiesNotice />}
            />
            <Route path="/*" element={<_404 />} />
          </Route>
          {/*Login page route*/}
          <Route
            element={
              <LoginRoute
                isAuth={isAuth}
                mfaEnabled={mfaEnabled}
                userType={userType}
                inHomeApp={inHomeApp}
                otp={otp}
              />
            }
          >
            <Route element={<LoginLayout />}>
              <Route path="/users/login" element={<LoginPage />} />
              <Route path="/sign-in" element={<LoginPage />} />
              <Route path="/password/edit" element={<ResetPasswordPage />} />
              <Route path="/users/fp" element={<ForgotPasswordPage />} />
              <Route path="/users/up" element={<PasswordUpdatePage />} />
              <Route
                path="/patients/invitation/accept"
                element={<PatientRegistrationPage />}
              />
              <Route
                path="/invitation/accept"
                element={<PatientRegistrationPage />}
              />
              <Route
                path="/admin_users/invitation/accept"
                element={<PatientRegistrationPage />}
              />
              <Route
                path="/pg2_invitation/accept"
                element={<PG2PasswordUpdatePage />}
              />
              <Route
                path="/admin_users/invitation/accept"
                element={<PatientRegistrationPage />}
              />
              <Route
                path="/pg2_user_landing_page"
                element={<Pg2UserLandingPage />}
              />
              <Route path="/*" element={<_404 />} />
            </Route>
          </Route>
          {/*MFA page route*/}
          <Route
            element={
              <MFARoute
                isAuth={isAuth}
                mfaEnabled={mfaEnabled}
                userType={userType}
                inHomeApp={inHomeApp}
                otp={otp}
              />
            }
          >
            <Route element={<LoginLayout />}>
              <Route
                path="/users/verify_otp"
                element={<OtpVerificationPage />}
              />
              <Route path="/users/domain" element={<DomainPage />} />
              <Route path="/*" element={<_404 />} />
            </Route>
          </Route>

          {/*Admin page route*/}
          <Route
            element={
              <AdminProtectedRoute
                isAuth={isAuth}
                mfaEnabled={mfaEnabled}
                userType={userType}
                inHomeApp={inHomeApp}
                otp={otp}
              />
            }
          >
            <Route element={<AdminLayout />}>
              <Route path="/admin/dashboard" element={<AdminDashboard />} />
              <Route
                path="/admin/practitioners"
                element={<AdminPractitionerList />}
              />
              <Route path="/admin/patients" element={<AdminPatientList />} />
              <Route
                path="/admin/report/practitioner"
                element={<PractitionerReport />}
              />
              <Route path="/admin/report/patient" element={<PatientReport />} />
              <Route path="/admin/users" element={<AdminUserList />} />
              <Route
                path="/admin/gdpr/patient"
                element={<AdminPatientGDPRList />}
              />
              <Route
                path="/admin/gdpr/practitioner"
                element={<AdminPracGDPRList />}
              />
              <Route
                path="/admin/production_task"
                element={<ProductionTask />}
              />
              <Route path="/admin/staffs" element={<AdminStaffList />} />
              <Route path="/*" element={<_404 />} />
            </Route>
          </Route>
          {/*Practice page route*/}
          <Route
            element={
              <PracticeProtectedRoute
                isAuth={isAuth}
                mfaEnabled={mfaEnabled}
                userType={userType}
                inHomeApp={inHomeApp}
                otp={otp}
              />
            }
          >
            <Route element={<PracticeLayout />}>
              <Route path="/loading" element={Loading} />
              <Route
                path="/practice/patient/add"
                element={<AddPatientPage />}
              />
              <Route
                path="/practice/patient/addbulk"
                element={<AddPatientBulkPage />}
              />
              <Route path="/practice/patient/catalog" element={<Catalog />} />
              <Route
                path="/practice/patient/report/diagram"
                element={<ReportDiagrams />}
              />
              <Route
                path="/practice/patient/report/gene_analysis"
                element={<GeneAnalysisSummary />}
              />
              <Route
                path="/practice/patient/report/supplemental_genotype"
                element={<SupplementalGenotype />}
              />
              <Route
                path="/practice/patient/report/trait"
                element={<TraitPage />}
              />
              <Route
                path="/practice/patient/report"
                element={<ReportSummary />}
              />
              <Route
                path="/practice/patient/report/ps_list/:report_id"
                element={<PrsScorePatient />}
              />
              <Route
                path="/practice/patient/lab/review"
                element={<ReviewLabResult />}
              />
              <Route path="/practice/patient/lab" element={<LabUpload />} />
              <Route
                path="/practice/patient/lab/manual_entry"
                element={<ManualEntry />}
              />
              <Route
                path="/practice/patient/snp"
                element={<PracticeGeneticUpload />}
              />
              <Route
                path="/practice/patient/past_report"
                element={<PastReport />}
              />
              <Route
                path="/practice/patients/gdpr"
                element={<PracticePatientGDPRLogs />}
              />
              <Route path="/practice/patients" element={<PatientList />} />
              <Route
                path="/practice/supplement_catalog"
                element={<RialtoPage />}
              />
              <Route
                path="/practice/view_patient_answers"
                element={<ViewPatientAnswers />}
              />
              <Route
                path="/practice/dashboard"
                element={<PractitionerDashboard />}
              />
              <Route
                path="/practice/account"
                element={<PractitionerAccount />}
              />
              <Route
                path="/practice/rialto_management"
                element={<RialtoManagement />}
              />
              <Route
                path="/practice/rialto_management_new"
                element={<RialtoPage />}
              />
              <Route
                path="/practice/manage_data"
                element={isUK ? <ManagePracticeData /> : <_404 />}
              />
              <Route
                path="/practice/stripe_redirect"
                element={<StripeRedirect />}
              />
              <Route path="/practice/order/:orderId" element={<RialtoPage />} />
              <Route path="/practice/staff" element={<StaffList />} />
              <Route path="/staff/account" element={<StaffAccount />} />
              <Route path="/users/domain_pl" element={<DomainPage />} />
              <Route
                path="/practice/report/new/:report_id"
                element={<ReportSummaryPage />}
              />
              <Route path="/*" element={<_404 />} />
            </Route>
          </Route>
          {/*Patient page route*/}
          <Route
            element={
              <PatientRoute
                isAuth={isAuth}
                mfaEnabled={mfaEnabled}
                userType={userType}
                inHomeApp={inHomeApp}
                otp={otp}
              />
            }
          >
            <Route element={<PatientLayout />}>
              <Route path="/patient/report/trait" element={<TraitPage />} />
              <Route path="/patient/report" element={<ReportSummary />} />
              <Route
                path="/patient/supplement_catalog"
                element={<RialtoPage />}
              />
              <Route path="/patient/snp" element={<GeneticUpload />} />
              <Route path="/patient/dashboard" element={<PG4Dashboard />} />
              <Route path="/patient/account" element={<PatientAccount />} />
              <Route
                path="/patient/questionnaire"
                element={<Questionnaire />}
              />
              <Route
                path="/patient/review_answer"
                element={<ReviewQuestion />}
              />
              <Route
                path="/patient/thank_you"
                element={<ThankYouQuestionnaire />}
              />
              <Route
                path="/patient/supplement_suggestions"
                element={<RialtoPage />}
              />
              <Route path="/patient/order/:orderId" element={<RialtoPage />} />
              <Route
                path="/patient/manage_data"
                element={isUK ? <ManageDataUK /> : <_404 />}
              />
              <Route
                path="/patient/dashboard"
                element={<PatientReportDashboard />}
              />
              <Route
                path="/patient/past_reports"
                element={<PatientPastReports />}
              />
              <Route path="/patient/msq/status" element={<MSQStatus />} />
              <Route path="/patient/msq" element={<MSQ />} />
              {/* <Route path="/patient/dashboard_v4" element={<PG4Dashboard />} /> */}
              <Route path="/patient/lab" element={<PatientLabUpload />} />
              <Route
                path="/patient/lab/manual_entry"
                element={<PatientManualEntry />}
              />
              <Route
                path="/patient/lab/review"
                element={<PatientReviewLabResult />}
              />
              <Route
                path="/patient/report/new/:report_id"
                element={<ReportSummaryPage />}
              />
              <Route path="/patient/shipping_cart" element={<RialtoPage />} />
              <Route path="/patient/order_history" element={<RialtoPage />} />
            </Route>
          </Route>
        </Route>
        <Route path="/*" element={<_404 />} />
      </Routes>
      <Footer userType={userType} />
    </>
  );
}

export default MainLayout;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import ExternalSitePrompt from "./ExternalSitePrompt";
import { useTranslation } from "react-i18next";
import useLanguageConditions from "../../hooks/useLanguageConditions";

function CustomerService() {
  const { t, i18n } = useTranslation();
  const { isCA, isUK, isUS } = useLanguageConditions();

  const [showExternalSitePrompt, setShowExternalSitePrompt] = useState(false);
  const [externalSiteLink, setExternalSiteLink] = useState("");

  return (
    <div>
      {showExternalSitePrompt && (
        <ExternalSitePrompt
          showModal={showExternalSitePrompt}
          externalSiteLink={externalSiteLink}
          onCancel={() => setShowExternalSitePrompt(false)}
        />
      )}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-11  my-5 px-md-0 px-3">
            <p className="pgs-title my-5">Customer Service</p>
            <div className="row justify-content-center">
              <div className="col-11 col-lg-12">
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Same Day Shipping
                </div>
                <div className="pgs-text my-2">
                  Orders received by 4:00 pm ET are shipped that day, via UPS
                  2-Day service.
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 col-lg-12">
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Our Guarantee & Return Policy
                </div>
                <div className="pgs-text my-2">
                  All Pure Encapsulations® products are guaranteed for quality,
                  purity, and potency through their expiration date. Returns
                  must be made within 30 days of receipt and require
                  preauthorization. Any claims of shortage or damage must be
                  made within 7 days of receiving the package. Authorized
                  returns must be received by our warehouse within 14 days of
                  authorization. They will be credited upon receipt.
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 col-lg-12">
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Shipping Charges & 2 Day Delivery
                </div>
                <div className="pgs-text my-2">
                  <ul className="pgs-text ff-m">
                    <li>
                      As the manufacturer, we carefully control our inventory.
                    </li>
                    <li>Orders placed before 4:00 pm ET will ship that day.</li>
                    <li>
                      Shipping is FREE for continental US orders over $100
                      (exceptions may apply).
                    </li>
                    <li>Orders under $100 please add $10.00 for shipping.</li>
                    <li>
                      Orders outside of our 1-2 day UPS delivery zone will ship
                      UPS 2-Day Air at no additional charge.
                    </li>
                    <li>
                      For Puerto Rico, and Alaska there is a $12 flat rate
                      shipping fee and free shipping is at $500. Orders to all
                      other US territories do not qualify for free shipping.
                      Shipping to these locations is billed separately based on
                      actual costs.
                    </li>
                    <li>We do not currently offer shipping to Hawaii.</li>
                    <li>
                      Please note that UPS does not deliver to P.O. Boxes and
                      will only accept shipments to a valid street address.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 col-lg-12">
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Payment Terms
                </div>
                <div className="pgs-text my-2">
                  Orders are shipped pre-paid by Discover, MasterCard, VISA or
                  American Express
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 col-lg-12">
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Sales Tax
                </div>
                <div className="pgs-text my-2">
                  Pure Encapsulations is required to collect and remit sales tax
                  for orders shipped to the following states:
                  <br />
                  Alabama, Arizona, Arkansas, California, Colorado, Georgia,
                  Idaho, Illinois, Indiana, Iowa, Kansas, Kentucky, Louisiana,
                  Maine, Massachusetts, Michigan, Minnesota, Mississippi,
                  Missouri, Nebraska, Nevada, New Mexico, North Carolina, North
                  Dakota, Ohio, Oklahoma, Rhode Island, South Carolina, South
                  Dakota, Tennessee, Utah, Virginia, Washington, Wisconsin. If
                  you are a Practitioner and are tax-exempt, please contact
                  Customer Service at 800-753-2277 to discuss the documentation
                  needed to make your account tax-exempt. If your order is
                  placed before these documents are sent in, we unfortunately
                  will not be able to refund you the applicable sales tax.
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 col-lg-12">
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Privacy Policy
                </div>
                <div className="pgs-text my-2">
                  Pure Encapsulations is committed to protecting the privacy of
                  our valued customers. Please see our &nbsp;
                  <Link
                    to={isCA ? "/privacy-policy" : "/privacy"}
                    className="textc-primary__p fw-bold d-inline-block cursor-pointer text-decoration-underline"
                  >
                    Privacy Policy
                  </Link>
                  .
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 col-lg-12">
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Forgot Password
                </div>
                <div className="pgs-text my-2">
                  If you've forgotten your password, simply enter your email
                  address on the reset password page, accessible from the login
                  page and an email will be sent with instructions on how to
                  reset your password.
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 col-lg-12">
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Supporting Materials
                </div>
                <div className="pgs-text my-2">
                  Pure Encapsulations offers a variety of online and printed
                  marketing materials for use in your practice. Educational
                  pamphlets on our quality control and manufacturing excellence
                  are available in packets of 25 and a display holder for these
                  pamphlets is also offered. All of these materials are free of
                  charge and can be found in the Education and Resources
                  drop-down menu, under &nbsp;
                  <span
                    onClick={() => {
                      setExternalSiteLink(
                        "https://www.pureencapsulationspro.com/education/practitioner-resources"
                      );
                      setShowExternalSitePrompt(true);
                    }}
                    className="textc-primary__p fw-bold d-inline-block cursor-pointer text-decoration-underline"
                  >
                    Practitioner Resources
                  </span>
                  .
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11 col-lg-12">
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Contact Us
                </div>
                <div className="pgs-text my-2">
                  Please visit the &nbsp;
                  <Link
                    to="/contact-us"
                    className="textc-primary__p fw-bold d-inline-block cursor-pointer text-decoration-underline"
                  >
                    Contact Us &nbsp;
                  </Link>
                  page
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerService;

import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function LabReviewUpdateSuccess({
  toPatientList,
  toUploadPage,
  onCancel,
  showModal,
  modalText,
}) {
  const { t, i18n } = useTranslation();
  const userType = useSelector((state) => state.auth.userType);
  return (
    <Modal
      show={showModal}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onCancel}
      contentClassName="border-radius"
    >
      <Modal.Header>
        {" "}
        <div className="pg-heading">{t("patient_thx_text1")}</div>
      </Modal.Header>
      <Modal.Body>
        <div className="pg-text ">
          {userType == "User"
            ? t("lab_success_message_prac")
            : t("lab_success_message_pat")}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        {/* <button className="btn bg-none text-dark px-5" onClick={onCancel}>
          Cancel
        </button> */}
        <button
          className="btn btn-sm btn-outline text-light px-4 mx-1 w-100 "
          onClick={toPatientList}
          title={t("txt_goto_patient_list")}
        >
          {t(modalText)}
        </button>
        <button
          className="btn btn-sm btn-outline text-light px-4 mx-1"
          onClick={toUploadPage}
          title={t("txt_goto_lab_upload")}
        >
          {t("txt_lab_upload")}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default LabReviewUpdateSuccess;
